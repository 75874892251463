<template>
  <icon-base :width="size + 'px'" :fill="color">
    <path class="st0" d="M162.9,2.2H37.1c-4.7,0-8.5,3.8-8.5,8.5v178.5c0,4.7,3.8,8.5,8.5,8.5h125.7c4.7,0,8.5-3.8,8.5-8.5V10.7  C171.4,6,167.6,2.2,162.9,2.2z M108,186.1c-0.5,3.4-3.3,6.2-6.7,6.7c-5.5,0.8-10.2-3.8-9.3-9.3c0.5-3.4,3.3-6.2,6.7-6.7  C104.2,175.9,108.9,180.6,108,186.1z M157.8,167.4c0,1.7-1.4,3.1-3.1,3.1H45.3c-1.7,0-3.1-1.4-3.1-3.1V21.1c0-1.7,1.4-3.1,3.1-3.1  h109.3c1.7,0,3.1,1.4,3.1,3.1V167.4z"/>`
  </icon-base>
</template>

<script>
export default {
  name: 'iconTablet',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

